<template>
  <div id="activitites">
    <div class="activities-filters">
      <h4>فلترة حسب:</h4>
      <b-row>
        <b-col
          ><ek-input-select
            clearable
            class="noLabel"
            name="المدينة"
            placeholder="المدينة"
            :options="cities"
            textLabel="langName"
            valueLabel="langName"
            v-model="filter.city"
          ></ek-input-select
        ></b-col>
        <b-col
          ><ek-date-picker
            class="noLabel"
            range
            placeholder="تاريخ التسجيل"
            name="تاريخ التسجيل"
            v-model="filter.dateCreate"
          ></ek-date-picker
        ></b-col>
        <b-col
          ><ek-date-picker
            class="noLabel"
            range
            placeholder="نهاية الاشتراك"
            name="تاريخ التسجيل"
            v-model="filter.endSub"
          ></ek-date-picker
        ></b-col>
      </b-row>
    </div>
    <div class="activities-list">
      <ek-table
        @delete-selected="deleteActivitesList"
        :items="activitesList.filter(filterActivities)"
        :columns="cols"
        @details="goToDetails"
        deleteBtn
      >
        <template slot="items.endSub" slot-scope="{ value }">
          {{ value ? new Date(value).toLocaleDateString() : "-" }}
        </template>
        <template slot="items.dateCreate" slot-scope="{ value }">
          {{ value ? new Date(value).toLocaleDateString() : "-" }}
        </template>
        <template slot="items.startSub" slot-scope="{ value }">
          {{ value ? new Date(value).toLocaleDateString() : "-" }}
        </template>
        <template slot="items.accountStatuses" slot-scope="{ value }">
          <StatusBadge
            :statusList="[
              { number: 1, name: 'غير فعال', color: 'danger' },
              { number: 2, name: ' فعال', color: 'primary' },
            ]"
            :selectedStatusNumber="value"
          ></StatusBadge>
          <!-- { number: 3, name: 'مقبول', color: 'success' },
              { number: 4, name: 'مقبول', color: 'success' }, -->
        </template>
      </ek-table>
    </div>
  </div>
</template>

<script>
import StatusBadge from "@global/components/StatusBadge.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { dateManipulatoin } from "@core/mixins/ui/dateManipulatoin";

export default {
  mixins: [dateManipulatoin],
  data: () => ({
    filter: {
      city: "",
      dateCreate: "",
      endSub: "",
    },
    cols: [
      {
        label: "اسم الفعالية",
        field: "accountName",
      },
      {
        label: "النشاط التجاري",
        field: "commerialActivityName",
      },
      {
        label: "المدينة",
        field: "cityName",
      },
      {
        label: "تاريخ التسجيل",
        field: "dateCreate",
      },
      {
        label: "عدد الاشتراكات",
        field: "subCount",
      },
      {
        label: "بداية الاشتراك",
        field: "startSub",
      },
      {
        label: "نهاية الاشتراك",
        field: "endSub",
      },
      {
        label: "حالة الحساب",
        field: "accountStatuses",
      },
      {
        label: "التفاصيل",
        field: "details",
      },
    ],
  }),
  components: {
    StatusBadge,
  },
  computed: {
    ...mapState({
      cities: ({ locations }) => locations.cities,
    }),
    ...mapGetters(["activitesList"]),
  },
  methods: {
    ...mapActions(["getActivitiesList", "getCities", "getCountries"]),
    goToDetails({ row }) {
      this.$router.push({
        path: `activities/${row.accountId}`,
        query: { username: row.accountName },
      });
    },
    deleteActivitesList(e) {
      this.$store.dispatch("deleteActivitesList", e);
    },
    filterActivities(el) {
      let [
        subscriptionStartDate = null,
        subscriptionEndDate = null,
      ] = this.filter.dateCreate?.replace("to", "").replace(" ", "").split(" ");

      let [
        expirationStartDate = null,
        expirationEndDate = null,
      ] = this.filter.endSub?.replace("to", "").replace(" ", "").split(" ");

      return (
        (el.cityName == this.filter.city || !this.filter.city) &&
        ((this.setDateTime(new Date(el.dateCreate)) >=
          this.setDateTime(new Date(subscriptionStartDate)) &&
          this.setDateTime(new Date(el.dateCreate)) <=
            this.setDateTime(new Date(subscriptionEndDate))) ||
          this.filter.dateCreate.length == 0) &&
        ((this.setDateTime(new Date(el.endSub)) >=
          this.setDateTime(new Date(expirationStartDate)) &&
          this.setDateTime(new Date(el.endSub)) <=
            this.setDateTime(new Date(expirationEndDate))) ||
          this.filter.endSub.length == 0)
      );
    },
  },
  created() {
    this.getActivitiesList();
  },
};
</script>

<style lang="scss"></style>
